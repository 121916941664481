import React from "react";
import InfoBox from "../../components/InfoBox";
import ScrnBox from "../../components/ScrnBox";
import classes from "../../styles/containers/project_base.module.scss";
import classesUni from "../../styles/containers/iot.module.scss"
import moistureVideo from "../../res/moisturemeterdemo.mp4";
import webserver from "../../res/Servowebdemo.mp4";
import iotPic from "../../res/servoweb.jpg";

const IoT = (props) => {
  return (
    <div className={classes.base_container}>
      <h1>IoT & Electronics</h1>
      <InfoBox
        sectionTitle="Lights on a Web Server"
        sectionInfo="The idea with this project was for me to be able to turn of my lights
        from my desktop/phone or any device that could load my webpage.
        |A web server on the Arduino serves a very basic webpage to my localhost and listens for a click on the 
        hyperlink. Once the link has been clicked the program tells a servo to move its arm
        from the resting position to 130 degrees where it can knock the switch on."
        splitFlag="|"
        styleVariant={2}
        alignCenter
        blackText
      />
      <div className={classesUni.sideby}>
        <video controls muted style={{ marginBottom: "2rem" }}>
          <source src={webserver} type="video/mp4" />
        </video>

        <ScrnBox
          splitFlag=""
          desc=""
          customImgWidth={450}
          imgAlt="Setup for web server lights"
          imgSrc={iotPic}
        />
      </div>

      <br />

      <InfoBox
        sectionTitle="Moisture-o-meter"
        sectionInfo="An offline and more simple project, the 'moisture-meter' simply took readings
        from a moisture sensor then used a calculation to turn those values into a bounded angle can could be
        represented with the angle of a servo gear.| Leaving you with a simple way to interpret the readings in 
        real time."
        splitFlag="|"
        styleVariant={2}
        alignCenter
        blackText
      />

      <video controls muted style={{ marginBottom: "2rem" }}>
        <source src={moistureVideo} type="video/mp4" />
      </video>
    </div>
  );
};

export default IoT;
